import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { queue } from "../notifications/notificationsSlice"

const initialState = {
    loading: false,
    data: undefined,
    error: undefined,
}

export const fetchStartpage = createAsyncThunk("startpage/fetchStartpageStatus", async (promise, { dispatch }) => {
    return new Promise(async (resolve, reject) => {
        promise
            .then(result => resolve(result))
            .catch(error => {
                dispatch(
                    queue({
                        message: "Daten konnten nicht geladen werden",
                        options: {
                            variant: "error",
                        },
                    })
                )
                reject(error)
            })
    })
})

export const startpageSlice = createSlice({
    name: "startpage",
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchStartpage.pending, (state, _) => {
            state.loading = true
            state.error = undefined
        })
        builder.addCase(fetchStartpage.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data?.attributes
            state.error = undefined
        })
        builder.addCase(fetchStartpage.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export default startpageSlice.reducer
