import { combineReducers, configureStore } from "@reduxjs/toolkit"
import imprintReducer from "./static/imprintSlice"
import startpageReducer from "./static/startpageSlice"
import karriereReducer from "./static/karriereSlice"
import notificationsReducer from "./notifications/notificationsSlice"


export const store = configureStore({
    reducer: {
        notifications: notificationsReducer,
        static: combineReducers({
            imprint: imprintReducer,
            startpage: startpageReducer,
            karriere: karriereReducer,
        }),
    },
})
